

const personsData = [
  {
    "id": 32,
    "name": "Chaitra S",
    "position": "Building exceptional teams @ Anakin!",
    "company": "Anakin",
    "profilePic": "https://media.licdn.com/dms/image/D5635AQGzxusJXA_9aA/profile-framedphoto-shrink_100_100/0/1716812157707?e=1719500400&v=beta&t=qSfccYnhl0GJckUM3xVchEus6EogcNVN3H8-EryLYgA",
    "linkedinPostUrl": "https://www.linkedin.com/posts/chaitra-s-4b923139_amazing-opportunity-for-software-engineers-activity-7205816415264657408-mTHd?utm_source=share&utm_medium=member_desktop",
    "linkedinProfileUrl": "https://www.linkedin.com/in/chaitra-s-4b923139?miniProfileUrn=urn%3Ali%3Afsd_profile%3AACoAAAgK5I0BDI4vzMGgmV3NhQURJkvlkf_bJtQ&lipi=urn%3Ali%3Apage%3Ad_flagship3_feed%3B7oUsm4%2BFQnqpMocC7R2Sdg%3D%3D"
},

  {
    "id": 31,
    "name": "Sristy Ghosh",
    "position": "Lawyer",
    "company": "",
    "profilePic": "https://media.licdn.com/dms/image/D4D03AQHxi-4H_IfSBQ/profile-displayphoto-shrink_100_100/0/1714977639541?e=1724284800&v=beta&t=l3NckDAXMclksaIsgwLoIiBevp3cZHYg1P1uJb2p3N0",
    "linkedinPostUrl": "https://www.linkedin.com/embed/feed/update/urn:li:share:7209464505968324608",
    "linkedinProfileUrl": "https://www.linkedin.com/in/sristyghosh?miniProfileUrn=urn%3Ali%3Afsd_profile%3AACoAABxMq2wBKq-i9DuCeRpLBhCdPJOZIppb45k&lipi=urn%3Ali%3Apage%3Ad_flagship3_feed%3B7oUsm4%2BFQnqpMocC7R2Sdg%3D%3D"
},

  {
    "id": 30,
    "name": "Ritu Ranjan Tiwari",
    "position": "SWE at Qualcomm",
    "company": "Qualcomm",
    "profilePic": "https://media.licdn.com/dms/image/C4E03AQHbdFtvrqZaGg/profile-displayphoto-shrink_100_100/0/1643050634301?e=1724284800&v=beta&t=kUVcrWusuFzdk8SG43H5IM5Ws2FTX6iOR8hLN_fD-CQ",
    "linkedinPostUrl": "https://www.linkedin.com/embed/feed/update/urn:li:share:7209547424917250048",
    "linkedinProfileUrl": "https://www.linkedin.com/in/ritu-ranjan-tiwari-4310401a1?miniProfileUrn=urn%3Ali%3Afsd_profile%3AACoAAC8rVmUB85an3Xvks-sYKeUD0VZXHIhq-MI&lipi=urn%3Ali%3Apage%3Ad_flagship3_feed%3BH1ng2MKbR82slerZE2Srpw%3D%3D"
},
 {
    "id": 29,
    "name": "Shivam Chhirolya",
    "position": "110K+ | Computer Vision & ML at Qualcomm",
    "company": "Qualcomm",
    "profilePic": "https://media.licdn.com/dms/image/D5603AQFteMviiI8t5g/profile-displayphoto-shrink_100_100/0/1718355530299?e=1724284800&v=beta&t=V8nwUQ0Js5l4MOgoTWkTZQD80TuAhwPNfQ3rjodxtyY",
    "linkedinPostUrl": "https://www.linkedin.com/embed/feed/update/urn:li:share:7209526518027309056",
    "linkedinProfileUrl": "https://www.linkedin.com/in/shivam-chhirolya?miniProfileUrn=urn%3Ali%3Afsd_profile%3AACoAAB0hKmcBZnn8DiQtJ5U-pIEAvVvD_mHFu5s&lipi=urn%3Ali%3Apage%3Ad_flagship3_feed%3BH1ng2MKbR82slerZE2Srpw%3D%3D"
},

 
  {
    "id": 28,
    "name": "Akansha Tripathi",
    "position": "Senior Recruiter | Technology & Product Hiring @ Airtel",
    "company": "Airtel",
    "profilePic": "https://media.licdn.com/dms/image/D5603AQFB8vasF1YYpA/profile-displayphoto-shrink_100_100/0/1718814542416?e=1724284800&v=beta&t=tFyxv1rKURYxFTvkzTCk4ElgJ0l2jV7PUEJO3gOyV3w",
    "linkedinPostUrl": "https://www.linkedin.com/embed/feed/update/urn:li:share:7209450607722602496",
    "linkedinProfileUrl": "https://www.linkedin.com/in/akansha-tripathi-a17288141?miniProfileUrn=urn%3Ali%3Afsd_profile%3AACoAACJj67MBCDR33CzA_AGgnSIOpQ5E_oKspE8&lipi=urn%3Ali%3Apage%3Ad_flagship3_feed%3BH1ng2MKbR82slerZE2Srpw%3D%3D"
},
 {
    "id": 27,
    "name": "Mohammad F.",
    "position": "Co-founder",
    "company": "LearnYard",
    "profilePic": "https://media.licdn.com/dms/image/D5603AQHvc5tIX9evfg/profile-displayphoto-shrink_100_100/0/1708613765950?e=1724284800&v=beta&t=scTEKD_6yke9UrDm2aLNPJc3WqbGQAIqIo9Gg0R0F5E",
    "linkedinPostUrl": "https://www.linkedin.com/embed/feed/update/urn:li:share:7207951513216851968",
    "linkedinProfileUrl": "https://www.linkedin.com/in/frazmohammad?miniProfileUrn=urn%3Ali%3Afsd_profile%3AACoAACWuxzgBaYtv9Q9reqZfh5OeZuH-nBlMAN4&lipi=urn%3Ali%3Apage%3Ad_flagship3_detail_base%3BgXum3kf8TAqQZNaIath49A%3D%3D"
},
{
    "id": 26,
    "name": "Arya Vardhan Mani Tripathi",
    "position": "Data Analyst",
    "company": "",
    "profilePic": "https://media.licdn.com/dms/image/D4D03AQFUuWb40SUXfw/profile-displayphoto-shrink_100_100/0/1713956790962?e=1724284800&v=beta&t=Q_23U344xNJP5JAzpdRzAxngfaPo1LRrdP1mN35Dobg",
    "linkedinPostUrl": "https://www.linkedin.com/embed/feed/update/urn:li:share:7207623886400585728",
    "linkedinProfileUrl": "https://www.linkedin.com/in/arya-vardhan-mani-tripathi-811079306?miniProfileUrn=urn%3Ali%3Afsd_profile%3AACoAAE322E0Baz4wy8CirKNsBs-Bd0kV33IJ5KQ&lipi=urn%3Ali%3Apage%3Ad_flagship3_detail_base%3BoSNM06SAQ7m2BBoxkJ6qFQ%3D%3D"
},
{
    "id": 25,
    "name": "Rani Sharma",
    "position": "Senior HR",
    "company": "Tech Mahindra",
    "profilePic": "https://media.licdn.com/dms/image/D4D03AQHMQsKDZIfs6A/profile-displayphoto-shrink_100_100/0/1689494957047?e=1723680000&v=beta&t=xv-u4hVNo9CLWHt5txUEW49OfBGoWJ0TEIk6sZ_yZVw",
    "linkedinPostUrl": "https://www.linkedin.com/embed/feed/update/urn:li:share:7207062918566219776",
    "linkedinProfileUrl": "https://www.linkedin.com/in/rani-sharma-75890a240?miniProfileUrn=urn%3Ali%3Afsd_profile%3AACoAADvvc7QBoGLfYxNpxjYdgoZnvPSpd9oZm-w&lipi=urn%3Ali%3Apage%3Ad_flagship3_detail_base%3BdaqNqK08QkGDlEPSFANUhA%3D%3D"
},

  {
    "id": 24,
    "name": "Utsav Chawla",
    "position": "Founder",
    "company": "Mild Orange",
    "profilePic": "https://media.licdn.com/dms/image/D4D03AQGw0m8dp4lhJA/profile-displayphoto-shrink_100_100/0/1691606706796?e=1723680000&v=beta&t=b90YWScIMCswmVy6OKhle5MFzhjeAoQIY9fNPo3x45M",
    "linkedinPostUrl": "https://www.linkedin.com/embed/feed/update/urn:li:share:7207036555490729985",
    "linkedinProfileUrl": "https://www.linkedin.com/in/utsav-chawla-a35187122?miniProfileUrn=urn%3Ali%3Afsd_profile%3AACoAAB5IKQkBwlmTDhdrvGPyf8gOvA41cyq8INE&lipi=urn%3Ali%3Apage%3Ad_flagship3_detail_base%3BFl1PiM19T9irE4OsvuDfVQ%3D%3D"
  },
  {
    "id": 23,
    "name": "Rahul Jindal",
    "position": "Tech Lead",
    "company": "Physics Wallah",
    "profilePic": "https://media.licdn.com/dms/image/C5103AQGwUBqHuXRJcg/profile-displayphoto-shrink_100_100/0/1582635278168?e=1723680000&v=beta&t=ZGMynEWcaqKgiCpK4e2JUDm9RnGdUTdIOE58XBQ35Xc",
    "linkedinPostUrl": "https://www.linkedin.com/embed/feed/update/urn:li:share:7207245144775938048",
    "linkedinProfileUrl": "https://www.linkedin.com/in/rahuljindaltech?miniProfileUrn=urn%3Ali%3Afsd_profile%3AACoAABUBUsABKFRz0q4QLdN3DibZqMq6_mrRYlw&lipi=urn%3Ali%3Apage%3Ad_flagship3_detail_base%3BCpC5ysyHSRCXmmKHI5czSA%3D%3D"
},

 
  {
    "id": 22,
    "name": "Shantanu Bedarkar",
    "position": "Director Of Engineering",
    "company": "Ridecell",
    "profilePic": "https://media.licdn.com/dms/image/C5103AQHK2tgIw8z4Rw/profile-displayphoto-shrink_400_400/0/1556797036315?e=1723680000&v=beta&t=hIcEftVroMQI3YN3K8nyIio_YCUGoBU3s26FX0hOVH8",
    "linkedinPostUrl": "https://www.linkedin.com/embed/feed/update/urn:li:share:7206536078642626560",
    "linkedinProfileUrl": "https://www.linkedin.com/in/shantanu-bedarkar-0161066?miniProfileUrn=urn%3Ali%3Afsd_profile%3AACoAAAEVVJIBrLHiKccBXso9y1u8Gs56dbYZSyk&lipi=urn%3Ali%3Apage%3Ad_flagship3_detail_base%3B29sd9oJSTyCJX9vWZ9KzwA%3D%3D"
  },  
  
  {
    "id": 21,
    "name": "Mohit Juneja",
    "position": "Software Engineer @MMT",
    "company": "MakeMyTrip",
    "profilePic": "https://media.licdn.com/dms/image/C4E03AQGZvhUZ37KGvA/profile-displayphoto-shrink_100_100/0/1592193876270?e=1723680000&v=beta&t=8l2LT1vBSEaM1wXWaPBLMknaChtuUAJ-l--XXlB8OzM",
    "linkedinPostUrl": "https://www.linkedin.com/embed/feed/update/urn:li:share:7206708378994147329",
    "linkedinProfileUrl": "https://www.linkedin.com/in/mohit-juneja-17067?miniProfileUrn=urn%3Ali%3Afsd_profile%3AACoAACSYu9IBlF6qSKhG3w9prhioshyPeZoR7fk&lipi=urn%3Ali%3Apage%3Ad_flagship3_detail_base%3By41aqSlsTsWqQsvO136jxg%3D%3D"
  },
  {
    "id": 20,
    "name": "Paras Singh",
    "position": "CEO - AcquX",
    "company": "AcquX",
    "profilePic": "https://media.licdn.com/dms/image/D5603AQFgaaivUGScRA/profile-displayphoto-shrink_400_400/0/1718272939235?e=2147483647&v=beta&t=pZ3YBXhf-L-k38w2z9S6zcS7SKXg_VcaZcpX9x90pD4",
    "linkedinPostUrl": "https://www.linkedin.com/embed/feed/update/urn:li:share:7206979523630751744",
    "linkedinProfileUrl": "https://www.linkedin.com/in/paras-singh-acqux?trk=public_post_embed_feed-actor-name"
  }
,  
  
  {
    "id": 19,
    "name": "Paras Goel",
    "position": "Senior Manager, Product",
    "company": "Wayfair",
    "profilePic": "https://media.licdn.com/dms/image/D5603AQEL0JBrB8WuUw/profile-displayphoto-shrink_400_400/0/1718156184925?e=1723680000&v=beta&t=xf1ehpngnWypy1jXNJ-fRNFkK8BiiNM73peFeU-xAmo",
    "linkedinPostUrl": "https://www.linkedin.com/embed/feed/update/urn:li:share:7206214453183352832",
    "linkedinProfileUrl": "https://www.linkedin.com/in/goelparas/overlay/about-this-profile/?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base%3BicRTb7%2BPTvGYb2ClBrVW0w%3D%3D"
},

  
  
  {
    "id": 18,
    "name": "Neha Ullal",
    "position": "Assistant Manager HR",
    "company": "Motilal Oswal",
    "profilePic": "https://media.licdn.com/dms/image/D5603AQHtseQvKzgULw/profile-displayphoto-shrink_100_100/0/1712130503554?e=1722470400&v=beta&t=Nk-w_4ucUi_Onzo7V2e6qJoYS6IHu_bj6gx8s0eaYUY",
    "linkedinPostUrl": "https://www.linkedin.com/embed/feed/update/urn:li:share:7201916094150766592",
    "linkedinProfileUrl": "https://www.linkedin.com/in/neha-ullal-79294b153?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAACT7Lp4Bk5xFfdb0N0eCotDt4sLNVw6HCiU&lipi=urn%3Ali%3Apage%3Ad_flagship3_feed%3BrhADPe3rRWGLKe2kbDecgQ%3D%3D"
  },
  
  {
    id: 17,
    name: 'Thripura Kukkadapu',
    position: 'Recruiter',
    company: 'Job Curator',
    profilePic: 'https://media.licdn.com/dms/image/D5603AQHUxSXELdJyEA/profile-displayphoto-shrink_100_100/0/1701223529308?e=1722470400&v=beta&t=rpdyU1AULvmIhDMqK5CyXoLDB7C-zxpubQfqtQ9FtPw',
    linkedinPostUrl: 'https://www.linkedin.com/embed/feed/update/urn:li:share:7201208024273723392',
    linkedinProfileUrl: 'https://www.linkedin.com/in/thripura-k?miniProfileUrn=urn%3Ali%3Afsd_profile%3AACoAACtClhgBN2F0XSE6bp1CX1eNgFofyaY998s&lipi=urn%3Ali%3Apage%3Ad_flagship3_detail_base%3BvZzcgZcARxCQtnMiW7bvog%3D%3D'
  },
  


  {
    id: 16,
    name: 'Soni Shaw',
    position: 'Product Manager',
    company: 'PhonePe',
    profilePic: 'https://media.licdn.com/dms/image/D5603AQEzl_jfw4YRvQ/profile-displayphoto-shrink_100_100/0/1684694792722?e=1722470400&v=beta&t=mBM6uHhpJfdG5OZFRNZGKf-s1j9TIJMYop9fmNFYs00',
    linkedinPostUrl: 'https://www.linkedin.com/embed/feed/update/urn:li:share:7201809008184156160',
    linkedinProfileUrl: 'https://www.linkedin.com/in/soni-shaw?miniProfileUrn=urn%3Ali%3Afsd_profile%3AACoAAB--WyMBdPO06-hf_WjnOuS29O1-zeWjNLQ&lipi=urn%3Ali%3Apage%3Ad_flagship3_detail_base%3BW6NOF8suRWOgwg4hFGD4BQ%3D%3D'
  },
  
  
  {
    id: 15,
    name: 'Saranya N',
    position: 'Senior Manager - HRBP',
    company: 'HRBP',
    profilePic: 'https://media.licdn.com/dms/image/C5603AQFgyfhQLJSSBg/profile-displayphoto-shrink_100_100/0/1625824928332?e=1722470400&v=beta&t=wGZYvDqOB0s_jqlUhpo8SqAlXmZKI5ZPzNxeXtOdb7w',
    linkedinPostUrl: 'https://www.linkedin.com/embed/feed/update/urn:li:share:7201792529325842432',
    linkedinProfileUrl: 'https://www.linkedin.com/in/saranyahr?miniProfileUrn=urn%3Ali%3Afsd_profile%3AACoAAAFsqZMB9kQZ6FufrENvjwYvqBAm8R-ZrV0&lipi=urn%3Ali%3Apage%3Ad_flagship3_detail_base%3BJ7tkGIuWSqSSVhu%2BvL4iEA%3D%3D'
  },
 
  {
    id: 14,
    name: 'Piyush Dutta',
    position: 'Senior Associate (Tech Hiring)',
    company: 'Deutsche Telekom Digital Labs',
    profilePic: 'https://media.licdn.com/dms/image/D5603AQHx2vo7tLBDoA/profile-displayphoto-shrink_100_100/0/1715884151668?e=1722470400&v=beta&t=eFwG_FJgQuol_sIqRRrtK6aETi0lycC10e2RBjIWaxY',
    linkedinPostUrl: 'https://www.linkedin.com/embed/feed/update/urn:li:share:7200755931196907520',
    linkedinProfileUrl: 'https://www.linkedin.com/in/piyush-dutta-aaa724152?miniProfileUrn=urn%3Ali%3Afsd_profile%3AACoAACTFNyIBi9k6-YkIm8XJAP5rJsLjenCiwHI&lipi=urn%3Ali%3Apage%3Ad_flagship3_detail_base%3BWE8X%2FeMtQJenStSt2af2IQ%3D%3D'
  },

  
  {
    id: 13,
    name: 'Tarleen Kaur',
    position: 'Human Resources Associate',
    company: 'F13 Technologies',
    profilePic: 'https://media.licdn.com/dms/image/D4D03AQEFY4yr_8kl4Q/profile-displayphoto-shrink_100_100/0/1666943485594?e=1722470400&v=beta&t=wvPNdzWEYKG4LZsepb2KutvXhbMlXbb5fsjP98Re50I',
    linkedinPostUrl: 'https://www.linkedin.com/embed/feed/update/urn:li:share:7200711105432731648',
    linkedinProfileUrl: 'https://www.linkedin.com/in/tarleen-kaur-5966021a7?miniProfileUrn=urn%3Ali%3Afsd_profile%3AACoAADBSSMIBMTfgXkTWYxHQpcnVxi7PFvunrDc&lipi=urn%3Ali%3Apage%3Ad_flagship3_detail_base%3B2upt8ihZQ1yeh2sgVTDn7Q%3D%3D'
  },
  {
    id: 12,
    name: 'Chiranjeev Gaggar',
    position: 'Strategy Consulting',
    company: 'Accenture',
    profilePic: 'https://media.licdn.com/dms/image/C5103AQEl4W8V85tIkg/profile-displayphoto-shrink_100_100/0/1536211279537?e=1722470400&v=beta&t=RN0nva0ZKJrCJQHdFMLDbrpy6UL1cdPXR64hyWN5Gmo',
    linkedinPostUrl: 'https://www.linkedin.com/embed/feed/update/urn:li:share:7197881120669982721',
    linkedinProfileUrl: 'https://www.linkedin.com/in/ACoAABw9dscBnBckaHVyKdswag0813HcaJ_eLBk?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAABw9dscBnBckaHVyKdswag0813HcaJ_eLBk&lipi=urn%3Ali%3Apage%3Ad_flagship3_myitems_savedposts%3B3aZckaGcTg2RS7SDtQP6xQ%3D%3D'
  },
  {
    id: 11,
    name: 'Aaliya Baxamusa',
    position: 'Talent Acquisition Specialist',
    company: 'InterviewBit',
    profilePic: 'https://media.licdn.com/dms/image/D4D03AQF_XFY8gEphaw/profile-displayphoto-shrink_100_100/0/1679079459515?e=1722470400&v=beta&t=ABv1m4srsjgRH9K5o7PffoMPACWegg_d32A23VV7-No',
    linkedinPostUrl: 'https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7196841844364738561',
    linkedinProfileUrl: 'https://www.linkedin.com/in/ACoAACGFhJIB_y_-MESlfNaHXebq4eV31hW4kOg?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAACGFhJIB_y_-MESlfNaHXebq4eV31hW4kOg&lipi=urn%3Ali%3Apage%3Ad_flagship3_myitems_savedposts%3B3aZckaGcTg2RS7SDtQP6xQ%3D%3D'
  },
  {
    id: 10,
    name: 'Nashit Meraan',
    position: 'SDET III',
    company: 'HashedIn by Deloitte',
    profilePic: 'https://media.licdn.com/dms/image/D4D03AQFVWn22Pf2MYw/profile-displayphoto-shrink_100_100/0/1666632753866?e=1722470400&v=beta&t=yPRkIrgD7yTCrUFVQUj_j7mF6T1mnIAVS-1gfX_TN_M',
    linkedinPostUrl: 'https://www.linkedin.com/embed/feed/update/urn:li:share:7199838406963576833',
    linkedinProfileUrl: 'https://www.linkedin.com/in/nashit-meraan-688136208?miniProfileUrn=urn%3Ali%3Afsd_profile%3AACoAADSza0gByiVemVlhm_Tm4JPsYqczdUlmN8E&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BOC%2F6nbknTZSxIvaLW%2F7rpA%3D%3D'
  },
  {
    id: 9,
    name: 'Sarthak Mittal (B.A.D CEO)',
    position: 'Founder & CEO',
    company: 'Badbusiness',
    profilePic: 'https://media.licdn.com/dms/image/D4D03AQHTkNgBgxbcdQ/profile-displayphoto-shrink_100_100/0/1711806516206?e=1722470400&v=beta&t=UfPb7ojDT_ba93SMO97ASUkMl6Y7msVawLs7ke401Hc',
    linkedinPostUrl: 'https://www.linkedin.com/embed/feed/update/urn:li:share:7200174082447855616',
    linkedinProfileUrl: 'https://www.linkedin.com/in/sarthak-cxo?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAD7SvM0BmstN9EXeidfFWTPtTr6uU2rmdE0&lipi=urn%3Ali%3Apage%3Ad_flagship3_feed%3BiUkOx3U3TsuCNvvf9U7A6g%3D%3D'
  },
  {
    id: 8,
    name: 'Priyanka Nagmoti',
    position: 'Campus Relations | Talent Acquisition',
    company: '',
    profilePic: 'https://media.licdn.com/dms/image/C5103AQEqL058RXbfFw/profile-displayphoto-shrink_100_100/0/1556202417683?e=1722470400&v=beta&t=8c1cmErvgzSApbsCJV5Pvsf0HnJmXe1vvngBD1RIg8g',
    linkedinPostUrl: 'https://www.linkedin.com/embed/feed/update/urn:li:share:7200092010886676480',
    linkedinProfileUrl: 'https://www.linkedin.com/in/ACoAAB7v0xwB9i0G0BtJe4giv__8j8e2UmrVTvE?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAB7v0xwB9i0G0BtJe4giv__8j8e2UmrVTvE&lipi=urn%3Ali%3Apage%3Ad_flagship3_myitems_savedposts%3B3aZckaGcTg2RS7SDtQP6xQ%3D%3D'
  },
  {
    id: 7,
    name: 'Farheen Naaz',
    position: 'Building STATXO',
    company: '',
    profilePic: 'https://media.licdn.com/dms/image/D5635AQEg-vaESZFqyg/profile-framedphoto-shrink_100_100/0/1715604434369?e=1717318800&v=beta&t=RxwpvysyzK7wlGSWqlSL_NY4ElqOILaNsoTMweJ2I1Y',
    linkedinPostUrl: 'https://www.linkedin.com/embed/feed/update/urn:li:share:7198274868511952896',
    linkedinProfileUrl: 'https://www.linkedin.com/in/ACoAAB0WAUABrSOggiFnEmCthDBUbuuI2QGgIho?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAB0WAUABrSOggiFnEmCthDBUbuuI2QGgIho&lipi=urn%3Ali%3Apage%3Ad_flagship3_myitems_savedposts%3B3aZckaGcTg2RS7SDtQP6xQ%3D%3D'
  },

  {
    id: 6,
    name: 'Saloni Kumari',
    position: 'DC Quality Engineer',
    company: '',
    profilePic: 'https://media.licdn.com/dms/image/D4D03AQEkjmgowQP8vw/profile-displayphoto-shrink_100_100/0/1700034454186?e=1722470400&v=beta&t=g54Xri3hvUhC58c_KwngiCM4-7lu5Od4Nfw3YLymkQE',
    linkedinPostUrl: 'https://www.linkedin.com/embed/feed/update/urn:li:share:7199970002475622400',
    linkedinProfileUrl: 'https://www.linkedin.com/in/saloni-kumari-ba42511b3?miniProfileUrn=urn%3Ali%3Afsd_profile%3AACoAADGxKmwBSBB8SLGRh8Wj0M5UB8b57S8TGmo&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BOC%2F6nbknTZSxIvaLW%2F7rpA%3D%3D'
  },
 
  {
    id: 5,
    name: 'Chaitanya Kaul',
    position: 'Sr. Analyst',
    company: 'American Express',
    profilePic: 'https://media.licdn.com/dms/image/C4D03AQGrkfG_DeIcLQ/profile-displayphoto-shrink_400_400/0/1643992382114?e=2147483647&v=beta&t=tPFs-KQ0sqfWeZbs_l3aJ6vPgUZzgYbFlNeoKXm25fY',
    linkedinPostUrl: 'https://www.linkedin.com/embed/feed/update/urn:li:share:7199777795076472833',
    linkedinProfileUrl: 'https://www.linkedin.com/in/chaitanya-kaul?miniProfileUrn=urn%3Ali%3Afsd_profile%3AACoAACeYD_wBkVwT1f1jnTl_AOAU8W1xnu_bFi8&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BOC%2F6nbknTZSxIvaLW%2F7rpA%3D%3D'
  },  
  
  {
      id: 1,
      name: 'Zoro Wu',
      position: 'Engineering Manager, YouTube',
      company: 'YouTube',
      profilePic: 'https://media.licdn.com/dms/image/C4D03AQFrbRSJ1pt4QQ/profile-displayphoto-shrink_100_100/0/1613629828800?e=1721865600&v=beta&t=RNcmYe2P3ITAMY8f12q1H0gltrMnmwXOXuRgswCmC0E',
      linkedinPostUrl: 'https://www.linkedin.com/embed/feed/update/urn:li:share:7199554325776396288',
      linkedinProfileUrl: 'https://www.linkedin.com/posts/zowu_software-engineering-manager-ii-youtube-activity-7199554326342631424-8A4q/?utm_source=share&utm_medium=member_android'
    },
    // Add other person objects here...
    {
      id: 2,
      name: 'Swapnil Saha',
      position: 'Upcoming BTSA',
      company: '@ZS Associates',
      profilePic: 'https://media.licdn.com/dms/image/D5603AQHrtJ9DXA2OCQ/profile-displayphoto-shrink_100_100/0/1689714896230?e=1721865600&v=beta&t=oLKPqMDuHS8oVdM3DZiRhfqJOz_BHh16Rv4NZxAURq0',
      linkedinPostUrl: 'https://www.linkedin.com/embed/feed/update/urn:li:share:7198737319661641729',
      linkedinProfileUrl: 'https://www.linkedin.com/embed/feed/update/urn:li:share:7198737319661641729'
    },
    {
      "id": 3,
      "name": "Mukarrum Pasha",
      "position": "Associate Director - Human Resources",
      "company": "Pramana",
      "profilePic": "https://static.licdn.com/aero-v1/sc/h/9c8pery4andzj6ohjkjp54ma2",
      "linkedinPostUrl": "https://www.linkedin.com/embed/feed/update/urn:li:share:7199693578258821120",
      "linkedinProfileUrl": "https://www.linkedin.com/in/mukarrumpasha?miniProfileUrn=urn%3Ali%3Afsd_profile%3AACoAAAIDRrABaFwkgQPb_LNIzlUsKkKHpjtm0rs&lipi=urn%3Ali%3Apage%3Ad_flagship3_detail_base%3BOKpEkjSHSYCcY2Q%2FO5Yoew%3D%3D"
    },
    {
      "id": 4,
      "name": "Pranjal Shahi",
      "position": "Software Developer",
      "company": "HashedIn by Deloitte",
      "profilePic": "https://media.licdn.com/dms/image/C4D03AQEETzuSoyH7dg/profile-displayphoto-shrink_400_400/0/1572448417061?e=1721865600&v=beta&t=7vL19oighf_Ci7ESzrI_cOPGtH3ihSUaRPXsEKDYE4c",
      "linkedinPostUrl": "https://www.linkedin.com/embed/feed/update/urn:li:share:7199718328288894976",
      "linkedinProfileUrl": "https://www.linkedin.com/in/pranjal-shahi-30bb75190/"
    }
    
  
  
  ];
  
  export default personsData;
  