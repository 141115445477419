import React from 'react';
import './Modal.css';

const Modal = ({ show, onClose, showCloseButton, onSignupOrLogin }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>Sign Up or Login</h2>
        <p>To continue and ask for referrals, please sign up or log in.</p>
        <div className="modal-buttons">
          <button className="modal-button" onClick={onSignupOrLogin}>
            Sign Up / Login
          </button>
          {showCloseButton && (
            <button className="modal-button" onClick={onClose}>
              Close
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
