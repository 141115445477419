import React, { useEffect, useState } from 'react';
import axios from 'axios';

const PersonsList = () => {
  const [persons, setPersons] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredPersons, setFilteredPersons] = useState([]);

  useEffect(() => {
    const fetchPersons = async () => {
      try {
        const response = await axios.get('http://localhost:5000/api/persons');
        setPersons(response.data);
      } catch (error) {
        console.error('Error fetching persons:', error);
      }
    };

    fetchPersons();
  }, []);

  useEffect(() => {
    // Filter persons based on search query
    const filtered = persons.filter(person =>
      person.position.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredPersons(filtered);
  }, [searchQuery, persons]);

  const handleSearchChange = event => {
    setSearchQuery(event.target.value);
  };

  return (
    <div>
      <h1>Persons List</h1>
      <input
        type="text"
        placeholder="Search by position..."
        value={searchQuery}
        onChange={handleSearchChange}
      />
      <ul>
        {filteredPersons.map(person => (
          <li key={person._id}>
            <h2>{person.name}</h2>
            <p>{person.position} at {person.company}</p>
            <img src={person.profilePic} alt={`${person.name}'s profile`} />
            <a href={person.linkedinProfileUrl}>LinkedIn Profile</a>
            <a href={person.linkedinPostUrl}>LinkedIn Post</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PersonsList;
