import React from 'react';
import './Navbar.css';

const Navbar = () => {
  return (
    <nav className="navbar">
      <a href="https://hirespoof.com/#google_vignette" aria-label="Home">Home</a>
      <a href="https://hirespoof.com/" aria-label="Resume ATS Checker">ATS Checker</a>
      <a href="https://hirespoof.com/jobs-list/?filter-orderby=newest&paged=1&filter-location%5B0%5D=76" aria-label="Remote Jobs">Remote Jobs</a>
    </nav>
  );
};

export default Navbar;
