// AdSenseComponent.js
import React, { useEffect } from 'react';

const AdSenseComponent = () => {
  useEffect(() => {
    if (window.adsbygoogle && process.env.NODE_ENV !== 'development') {
      window.adsbygoogle.push({});
    }
  }, []);

  return (
    <div>
      {/* AdSense Ad Unit 1 */}
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-5241395065023122"
        data-ad-slot="6041308247"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
      
      {/* AdSense Ad Unit 2 */}
      <ins
        className="adsbygoogle"
        style={{ display: 'block', textAlign: 'center' }}
        data-ad-layout="in-article"
        data-ad-format="fluid"
        data-ad-client="ca-pub-5241395065023122"
        data-ad-slot="2568373224"
      ></ins>
    </div>
  );
};

export default AdSenseComponent;
