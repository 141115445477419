import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import PersonDetails from './components/PersonDetails'; // Updated to use PersonDetails
import PersonsList from './components/PersonsList';
import './App.css';
 import AdSenseComponent from './components/AdSenseComponent';// Import AdSenseComponent*

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/details/:id" element={<PersonDetails />} /> {/* Updated path to use PersonDetails */}
          <Route path="/persons" element={<PersonsList />} />
        </Routes>
        <AdSenseComponent /> 

      </div>
    </Router>
  );
}

export default App;
